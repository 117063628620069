import React, { useEffect, useState } from "react";
import {
  Card,
  Grid,
  Button,
  Select,
  Divider,
  MenuItem,
  TextField,
  Container,
  Typography,
  InputLabel,
  FormControl,
  CardContent,
  CardActions,
  InputAdornment,
  CircularProgress,
} from "@material-ui/core";
import {
  getProveedoresService,
  getRetencionesService,
  anularRetencionService,
  getTotalRetencionesService,
  cambiarEstadoRetencionService,
} from "./RetencionesServices";
import {
  optionsPeriod,
  columnas,
  formatRetenciones,
  ContentModalAnulacionRetencion,
} from "./Util";
import { firtDayCurrenMonth, lastDayCurrenMonth } from "../../../utils/dates";
import { successNotification } from "../../../components/Notifications";
import { formatearMoneda } from "../../../components/ait-reusable/FormatearMoneda/formatMoneda";
import moment from "moment";
import Period from "../../../components/ait-reusable/PeriodFilter/Period";
import PageHeader from "../../../components/ait-reusable/PageHeader";
import SearchIcon from "@material-ui/icons/Search";
import Pagination from "react-js-pagination";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ModalConfirm from "../../../components/ait-reusable/ModalConfirm/ModalConfirm";
import ReusableTable from "../../../components/ait-reusable/Report/ReusableTable";
import {
  getBlobPdf,
  imprimirPdf,
} from "../../../components/ait-reusable/HandlePdf/handlePdf";
import { useSelector } from "react-redux";
import { Showmodal } from "../../../components/ait-reusable/PDF/Components/PreviewPdf";
import CircularBackdrop from "../../../components/ait-reusable/CircularBackdrop";
import TooltipMoreInfoMaterial from "../../../components/ait-reusable/TooltipMoreInfo/TooltipMoreInfoMaterial";
import HeaderTable from "./HeaderTable";

export default function Retenciones({ history }) {
  const [period, setPeriod] = useState("thisMonth");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [retenciones, setRetenciones] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [totalRetenciones, setTotalRetenciones] = useState(null);
  const [retencionSelected, setRetencionSelected] = useState(null);
  const [proveedorSelected, setProveedorSelected] = useState(null);
  const [estadoRetencionSelected, setEstadoRetencionSelected] = useState(null);
  const [modalAnulacionRetencion, setModalAnulacionRetencion] = useState(false);

  const { configGeneral } = useSelector((state) => state.configGeneral);

  const [dates, setDates] = useState({
    fromDate: firtDayCurrenMonth(),
    toDate: lastDayCurrenMonth(),
  });
  const [pagination, setPagination] = useState({
    actualPage: 1,
    num_pages: 0,
    num_items: 0,
    next_page: null,
  });

  const handleGetRetenciones = async () => {
    setLoading(true);
    const response = await getRetencionesService(
      dates,
      estadoRetencionSelected,
      pagination,
      proveedorSelected,
    );
    if (response.status === 204) {
      setRetenciones([]);
      setPagination({
        actualPage: 1,
        num_pages: 0,
        num_items: 0,
        next_page: null,
      });
    } else {
      setRetenciones(response.data.data.items);
      setPagination({
        ...pagination,
        num_items: response.data.data.num_items,
        num_pages: response.data.data.num_pages,
        next_page: response.data.data.next_page,
      });
    }
    setLoading(false);
  };

  const handleGetTotalRetenciones = async () => {
    const response = await getTotalRetencionesService(proveedorSelected);
    setTotalRetenciones(response.data.total_retenciones);
  };

  const handleGetProveedores = async () => {
    const response = await getProveedoresService();
    setProveedores(response.data.data);
  };

  const handleCambiarEstadoRetencion = async (retencion, value) => {
    if (value === "Anulada") {
      handleModalAnulacionRetencion(retencion);
    } else {
      const response = await cambiarEstadoRetencionService(retencion, value);
      handleGetRetenciones();
      handleGetTotalRetenciones();
      successNotification("Retención actualizada");
    }
  };

  const handleAnularRetencion = async () => {
    const response = await anularRetencionService(retencionSelected);
    setModalAnulacionRetencion(false);
    handleGetRetenciones();
    handleGetTotalRetenciones();
    successNotification("Retención anulada con éxito");
  };

  const handleModalAnulacionRetencion = (retencion) => {
    setModalAnulacionRetencion(true);
    setRetencionSelected(retencion);
  };

  const handleAplicarFiltros = () => {
    handleGetRetenciones();
    handleGetTotalRetenciones();
  };

  const handleComprobantePdf = async (retencion) => {
    setBackdrop(true);
    setRetencionSelected(retencion);
    const blobPdf = await getBlobPdf("retencion", retencion);
    if (configGeneral.previsualizacion) {
      imprimirPdf(blobPdf);
    } else {
      imprimirPdf(blobPdf);
      setPreview(true);
    }

    setBackdrop(false);
  };

  const hasNotaCreditoDescuento =
    retencionSelected &&
    retencionSelected.pago_compra.ncs_de_pago.filter(
      (x) => x.es_descuento === true,
    );
  useEffect(() => {
    handleGetRetenciones();
    handleGetProveedores();
    handleGetTotalRetenciones();
  }, []);

  return (
    <Container maxWidth className="main-content-container px-4">
      <PageHeader
        title={"RETENCIONES"}
        subtitle={"Mis Retenciones"}
        history={history}
      />
      <Grid container spacing={2} className="pt-3" alignItems="flex-start">
        <Grid item container xs={12} md={4} lg={4}>
          <Grid item xs={12} md={12} lg={12}>
            <Card>
              <div className={"justify-content-center"}>
                <Typography
                  color="textSecondary"
                  style={{
                    fontSize: 12,
                    fontWeight: "bold",
                    textAlign: "center",
                    padding: 10,
                  }}
                >
                  TOTAL EN RETENCIONES VIGENTES
                  <TooltipMoreInfoMaterial
                    position={"top"}
                    titleTooltip={
                      <h6
                        style={{ fontSize: 15, marginBottom: 0, marginTop: 0 }}
                      >
                        Este monto corresponde a la sumatoria de todas las
                        retenciones vigentes de todos los proveedores.
                      </h6>
                    }
                  />
                </Typography>
              </div>
              <Divider />
              <CardContent style={{ textAlign: "center", padding: 10 }}>
                {loading ? (
                  <CircularProgress style={{ color: "rgb(0, 123, 255)" }} />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h5"
                      style={{
                        fontWeight: "bold",
                        textAlign: "center",
                        paddingLeft: 2,
                      }}
                    >
                      {formatearMoneda(totalRetenciones)}
                    </Typography>
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={12} className="pt-3">
            <Period
              period={period}
              setPeriod={setPeriod}
              dates={dates}
              setDates={setDates}
              clear={() => {}}
              searchOnLoad={false}
              optionsPeriod={optionsPeriod}
              errors={errors}
              setErrors={setErrors}
              sizeGrid={12}
              noButton={true}
            >
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControl size={"small"} fullWidth variant="outlined">
                  <InputLabel id="lbl-tipo-libro">Estado Retencion</InputLabel>
                  <Select
                    labelId="lbl-tipo-libro"
                    id="select-tipo-libro"
                    value={estadoRetencionSelected}
                    onChange={(e) => setEstadoRetencionSelected(e.target.value)}
                    label="Estado Retencion"
                  >
                    <MenuItem value={null}>{"Todas las retenciones"}</MenuItem>
                    <MenuItem value={"Anulada"}>{"Anulada"}</MenuItem>
                    <MenuItem value={"Pagada"}>{"Pagada"}</MenuItem>
                    <MenuItem value={"Vigente"}>{"Vigente"}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <FormControl size={"small"} fullWidth variant="outlined">
                  <Autocomplete
                    name="proveedor"
                    id="proveedor"
                    fullWidth
                    autoHightlight
                    value={proveedorSelected}
                    options={proveedores}
                    size="small"
                    getOptionSelected={(proveedor) =>
                      proveedor.razonSocial || ""
                    }
                    getOptionLabel={(proveedor) => proveedor.razonSocial}
                    noOptionsText={"No se encontraron resultados"}
                    onChange={(event, value) => setProveedorSelected(value)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        fullWidth
                        label="Búsqueda de proveedor"
                        placeholder="Razón social *"
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} style={{ paddingBottom: 20 }}>
                <Button
                  onClick={handleAplicarFiltros}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  CONSULTAR
                </Button>
              </Grid>
            </Period>
          </Grid>
        </Grid>
        <Grid item xs={12} md={8} lg={8}>
          <Card>
            <CardContent>
              <ReusableTable
                columns={columnas}
                loading={loading}
                items={formatRetenciones(
                  retenciones,
                  handleCambiarEstadoRetencion,
                  handleComprobantePdf,
                )}
                noResults="No se encontraron retenciones"
              >
                <HeaderTable />
              </ReusableTable>
              <CardActions>
                <Grid
                  container
                  spacing={2}
                  className="d-flex mt-3 mb-0 justify-content-center"
                >
                  <Pagination
                    activePage={pagination.actualPage}
                    itemsCountPerPage={10}
                    totalItemsCount={pagination.num_items}
                    pageRangeDisplayed={6}
                    onChange={(e) =>
                      setPagination({ ...pagination, actualPage: e })
                    }
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </Grid>
              </CardActions>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      {modalAnulacionRetencion && (
        <ModalConfirm
          open={modalAnulacionRetencion}
          handleSubmit={handleAnularRetencion}
          disabled={hasNotaCreditoDescuento.length}
          handleClose={() => setModalAnulacionRetencion(false)}
          title={`Confirmar anulación del movimiento ${retencionSelected.id}`}
          body={
            <ContentModalAnulacionRetencion
              retencionSelected={retencionSelected}
              hasNotaCreditoDescuento={hasNotaCreditoDescuento}
            />
          }
          buttons={{ submit: "Aceptar", cancel: "Cancelar" }}
        />
      )}

      {preview && (
        <Showmodal
          tipo={"retencion"}
          preview={preview}
          toggle={() => {
            setPreview(false);
            setRetencionSelected(null);
          }}
          data={retencionSelected}
        />
      )}
      <CircularBackdrop openBackdrop={backdrop} />
    </Container>
  );
}
