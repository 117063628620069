import React from "react";
import ModalReutilizable from "../Modal";
import { Grid, Typography } from "@material-ui/core";
import ButtonAceptar from "../Button/ButtonAceptar";

export default function ModalValidacionClientCAE({
  open,
  handleClose,
  idVenta,
  isNewVenta,
}) {
  const getContentModal = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ textAlign: "center", fontSize: 17 }}
          >
            {isNewVenta
              ? `La venta Nº ${
                  idVenta || ""
                } se generó con éxito pero no se pudo validar con AFIP y está pendiente de CAE.`
              : `Se registró la factura pero ocurrió un error al validar la misma con AFIP y quedó pendiente de CAE.`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="h6"
            style={{ textAlign: "center", fontSize: 17 }}
          >{`¿Desea reintentar la generación de CAE? ${
            isNewVenta
              ? "Puede reintentarlo despues de completar los datos del cliente desde el informe de ventas."
              : "Puede reintentarlo mas tarde."
          }`}</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <ModalReutilizable
      open={open}
      handleClose={handleClose}
      title="Validación de CAE"
      content={getContentModal()}
      actions={
        <ButtonAceptar click={() => handleClose()} message={"ACEPTAR"} />
      }
    />
  );
}
