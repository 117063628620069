import React, { useEffect, useState } from "react";
import { Grid, Typography, TextField, Chip } from "@material-ui/core";
import { ValidarEmail } from "../../../utils/validateEmail";
import { useSelector } from "react-redux";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
import ModalReutilizable from "../Modal";

export default function ModalSendEmail({
  open,
  toggle,
  reporte,
  dataEmail,
  sendEmail,
  facturasAdeudadas,
}) {
  const { configGeneral } = useSelector((state) => state.configGeneral);
  const [loading, setLoading] = useState(false);
  const [errorCorreo, setErrorCorreo] = useState(false);
  const [newCorreoCliente, setNewCorreoCliente] = useState(
    dataEmail.correoCliente,
  );

  const handleChangeEmail = (e) => {
    setErrorCorreo(false);
    setLoading(false);
    setNewCorreoCliente(e.target.value);
  };

  const validateEmail = () => {
    setLoading(true);
    if (newCorreoCliente === "" || !ValidarEmail(newCorreoCliente)) {
      setErrorCorreo(true);
    } else {
      sendEmail(newCorreoCliente, setLoading);
    }
  };

  const getTitle = () => {
    let title = "";
    let info = "";

    switch (reporte) {
      case "Presupuesto":
        title = "Envío de email de";
        info = ` Presupuesto N° ${dataEmail.id_presupuesto}`;
        break;
      case "Venta":
        title = "Envío de email de";
        info = ` Venta N° ${dataEmail.idVenta}`;
        break;
      case "Deuda":
        title = "Reporte deuda de";
        info = ` ${dataEmail.cliente}`;
        break;
      case "Remito":
        title = "Envío de email de";
        info = ` Remito N° ${dataEmail.idRemito}`;
        break;
      default:
    }

    return (
      <label htmlFor="">
        {title}
        <span className="fontBold">{info}</span>
      </label>
    );
  };

  return (
    <ModalReutilizable
      open={open}
      handleClose={toggle}
      title={getTitle()}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Typography>
              Se enviará el email a la siguiente dirección de correo
              electrónico. Por favor, verifique la dirección o agregue una
              nueva.
            </Typography>
          </Grid>
          <Grid item xs={12} lg={12}>
            <TextField
              label="Enviar a"
              error={errorCorreo}
              variant="outlined"
              size="small"
              fullWidth
              value={newCorreoCliente}
              onChange={(e) => handleChangeEmail(e)}
              helperText={errorCorreo && "El correo electrónico no es válido."}
            />
          </Grid>

          <Grid item xs={12} lg={12}>
            <Chip
              style={{
                marginTop: "-1rem",
                padding: "0.5rem",
                color: "#ffffff",
                backgroundColor: configGeneral.email ? "#3f51b5" : "#ff9800",
              }}
              size="small"
              label={
                configGeneral.email
                  ? "Se enviará el comprobante a tu email para corroborar su llegada"
                  : "No recibirás el comprobante en tu email porque no tienes uno asociado a tu cuenta"
              }
              icon={
                configGeneral.email ? (
                  <InfoIcon style={{ color: "#ffffff" }} />
                ) : (
                  <WarningIcon style={{ color: "#ffffff" }} />
                )
              }
            />
          </Grid>
        </Grid>
      }
      messageAceptar={"Aceptar"}
      messageCancelar={"Cancelar"}
      handleSubmit={validateEmail}
      disabled={loading}
    />
  );
}
