import React, { useState } from "react";
import OpcionesCatalogo from "./Catalogo/OpcionesCatalogo";
import { exportStock } from "../../requests/urls";

import { errorNotification } from "../../components/Notifications";
import axios from "axios";
import ModalActualizacionListas from "./ModalActualizacionListas";
import ModalUpdateIndex from "./ModalUpdateIndex";
import ModalUpdateDescargaGoogle from "./ModalUpdateDescargaGoogle";
import { useSelector } from "react-redux";
import meliLogo from "../../images/meli.png";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import moment from "moment";
import ModalCargaListaManual from "./listasManuales/ListaCargaListas/components/ModalCargaListaManual";
import columnasPorSucursales from "./Catalogo/columnasPorSucursales";
import { Box, Typography } from "@material-ui/core";
import { esUsuarioAdministrador } from "../../utils/esUsuarioAdministrador";
let lodash = require("lodash");

export const handleRenderParametrosProveedo = (parametros) => {
  const isPrecioLista = parametros.is_precio_lista;

  return (
    <Box>
      <Typography style={{ fontSize: 12 }}>
        IVA:{" "}
        {parametros.porcentaje_iva &&
          Number(parametros.porcentaje_iva).toFixed(2)}
        %
      </Typography>
      {isPrecioLista ? (
        <Typography style={{ fontSize: 12 }}>
          Descuento:{" "}
          {parametros.descuento && Number(parametros.descuento).toFixed(2)}%
        </Typography>
      ) : (
        <Typography style={{ fontSize: 12 }}>
          Recargo Lista:{" "}
          {parametros.recargo_lista &&
            Number(parametros.recargo_lista).toFixed(2)}
          %
        </Typography>
      )}
      <Typography style={{ fontSize: 12 }}>
        Recargo Contado:{" "}
        {parametros.recargo_contado &&
          Number(parametros.recargo_contado).toFixed(2)}
        %
      </Typography>
    </Box>
  );
};

//Proveedores
export const formatProveedores = (
  empleado,
  proveedores,
  history,
  setOpenBackdrop,
  handleOpenModalWhatsApp,
  getProveedores,
  tienePermisoVerDeuda, // Nuevo parámetro para verificar el permiso
) => {
  const esAdministrador = esUsuarioAdministrador(empleado);

  return proveedores.map((proveedor) => {
    // Objeto base
    let proveedorFormateado = {
      id: proveedor.idProveedor,
      lastUpdate: proveedor.last_update.state
        ? moment(proveedor.last_update.date_time)
            .calendar(null, {
              /**dejo comentado estás líneas por si en un futuro hay que modificarlo :) */
              // lastDay: "[Yesterday]",
              // sameDay: "[Today]",
              // nextDay: "[Tomorrow]",
              // lastWeek: "[last] dddd",
              // nextWeek: "dddd",
              lastWeek: "l HH:mm",
              sameElse: "l HH:mm",
            })
            .toUpperCase()
        : "- - -",
      proveedor: proveedor.razonSocial.toUpperCase(),
      cuit: proveedor.CUIT || proveedor.numeroDocumento,
      // ...otros campos que siempre aparecen
    };

    if (esAdministrador) {
      const listaPrecio = proveedor.parametros.find(
        (lp) =>
          lp.is_active &&
          lp.is_precio_lista == proveedor.is_precio_lista &&
          lp.es_default,
      );

      proveedorFormateado = {
        ...proveedorFormateado,
        porcentajes: listaPrecio
          ? handleRenderParametrosProveedo(listaPrecio)
          : null,
      };
    }

    // Agregar la deuda si el usuario tiene permiso
    if (tienePermisoVerDeuda) {
      proveedorFormateado = {
        ...proveedorFormateado,
        deuda: proveedor.deuda,
      };
    }

    // Agregar los campos restantes
    proveedorFormateado = {
      ...proveedorFormateado,
      telefono: proveedor.telefonoContacto || "--",
      estadoTributario: proveedor.estadoTributario,
      opciones: (
        <OpcionesProveedor
          proveedor={proveedor}
          history={history}
          setOpenBackdrop={setOpenBackdrop}
          handleOpenModalWhatsApp={handleOpenModalWhatsApp}
          getProveedores={getProveedores}
        />
      ),
    };

    return proveedorFormateado;
  });
};

export const filterProveedor = (id, proveedores) => {
  /**el id en una actualizacion se cambio ultima fecha de actualizacion
   * entonces ahora se hace este función por la razon social
   */
  let tempResult = proveedores.filter(
    (proveedor) => proveedor.idProveedor === id,
  );
  return tempResult.length !== 0 && tempResult[0];
};

const Editar = ({ idProveedor, history }) => {
  return (
    <div>
      <span
        title={"EDITAR"}
        className="material-icons"
        onClick={() => {
          history.push(`/proveedor/modificar/${idProveedor}/`);
        }}
      >
        edit
      </span>
    </div>
  );
};

const OpcionesProveedor = ({
  proveedor,
  history,
  setOpenBackdrop,
  handleOpenModalWhatsApp,
  getProveedores,
}) => {
  const [isOpenModalCargaManual, setOpenModalCargaManual] =
    React.useState(false);
  const { empleado, perfil } = useSelector((state) => state.loginReducer);
  const isVendedor =
    perfil === "Vendedor" || perfil === "Empleado" ? true : false;

  return (
    <>
      {!isVendedor && (
        <Editar idProveedor={proveedor.idProveedor} history={history} />
      )}

      {proveedor.actualizacion_manual && proveedor.has_url && (
        <div>
          <span
            className="material-icons"
            title="Cargar lista de precios manual"
            onClick={() => setOpenModalCargaManual(true)}
          >
            upload_file
          </span>
        </div>
      )}

      {empleado.nombre === "Ait" && (
        <div>
          <ModalActualizacionListas
            proveedor={proveedor}
            setOpenBackdrop={setOpenBackdrop}
            getProveedores={getProveedores}
          />
        </div>
      )}

      {empleado.nombre === "Ait" && (
        <div>
          <ModalUpdateIndex
            setOpenBackdrop={setOpenBackdrop}
            provider={proveedor.idProveedor}
          />
        </div>
      )}

      {empleado.nombre === "Ait" &&
        proveedor.has_url &&
        !proveedor.actualizacion_manual && (
          <div>
            <ModalUpdateDescargaGoogle
              setOpenBackdrop={setOpenBackdrop}
              provider={proveedor}
            />
          </div>
        )}

      {isOpenModalCargaManual && (
        <div>
          <ModalCargaListaManual
            open={isOpenModalCargaManual}
            proveedor={proveedor}
            handleClose={() => setOpenModalCargaManual(false)}
          />
        </div>
      )}

      <div>
        <WhatsAppIcon onClick={() => handleOpenModalWhatsApp(proveedor)} />
      </div>
    </>
  );
};

//Catalogo de articulos
const StockDisponible = ({ articulo }) => (
  <span
    style={{
      color: articulo
        ? articulo.stockDisponible > 0
          ? "green"
          : "red"
        : "black",
      fontWeight: "bold",
    }}
  >
    {articulo ? articulo.stockDisponible : "---"}
  </span>
);

export function exportStockExcel() {
  let url = exportStock;
  // let params = {desde: dates.fromDate, hasta: dates.toDate}
  let fileName = `Stock.xlsx`;
  axios({
    url: url,
    // params: params,
    method: "GET",
    headers: {
      Authorization: `Token ${localStorage.getItem("token")}`,
      "Content-Disposition": `attachment; filename=${fileName}`,
      "Content-Type":
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    },
    responseType: "arraybuffer", // important
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
    })
    .catch((err) => {
      console.log(err);
      errorNotification(
        "Ocurrio un error en el proceso intente nuevamente en unos minutos",
      );
    });
}

export const columns = (config, sucursales) => {
  // const { sucursales } = useSelector((state) => state.loginReducer);
  // Función encargada de manejar las columnas en base a las sucursales que tenga si es que tiene
  return columnasPorSucursales(sucursales, esVisible, config);
};
export const esVisible = (config, nameColumn) =>
  config && item(config, nameColumn) && item(config, nameColumn).es_visible;

const item = (config, nameColumn) => {
  const column = config.find((x) => x.nombre === nameColumn);
  return column;
};

export const formatArticulos = (
  articulos,
  getPreciosActualizados,
  actualizarPage,
  history,
  config,
  setFilterOriginal,
  getArticulosSucursal,
  anchorEl,
  setAnchorEl,
  handleClickMenu,
  handleCloseAnchor,
  handleOpenVincularStock,
  sucursales,
) => {
  let columnsVisible = [];
  articulos.map((articulo) => {
    let objectCol = {};

    esVisible(config, "Artículo") && articulo.repuesto_sucursal
      ? (objectCol.codProveedor = openAjustarStock(
          handleClickMenu,
          articulo,
          articulo.codProveedor.toUpperCase() || "---",
        ))
      : (objectCol.codProveedor = articulo.codProveedor.toUpperCase() || "---");

    esVisible(config, "Original") &&
      (objectCol.original = seteoFilter(
        articulo.codOriginal.toUpperCase() || "---",
        setFilterOriginal,
      ));

    esVisible(config, "Auxiliar") &&
      (objectCol.auxiliar = articulo.codAuxiliar
        ? seteoFilter(articulo.codAuxiliar.toUpperCase(), setFilterOriginal)
        : "---");

    objectCol.descripcion = (
      <React.Fragment>
        {articulo.meli_publicacion && (
          <img
            width={25}
            src={meliLogo}
            alt="meliLogo"
            title="Vinculado con mercadolibre"
          />
        )}
        {redirectToML(articulo.descripcionProveedor || "---")}
      </React.Fragment>
    );
    esVisible(config, "Observaciones") &&
      (objectCol.observacion = (
        <React.Fragment>
          {articulo.observaciones.length
            ? articulo.observaciones[0].substr(0, 45).trim() +
              (articulo.observaciones[0].length > 45 ? "..." : "")
            : "---"}
        </React.Fragment>
      ));

    esVisible(config, "P. Costo") &&
      (objectCol.precioCosto = parseFloat(
        articulo.precios.costo,
      ).toLocaleString("es-AR"));

    esVisible(config, "P. Lista") &&
      (objectCol.precioLista = parseFloat(
        articulo.precios.lista,
      ).toLocaleString("es-AR"));

    esVisible(config, "P. Venta") &&
      (objectCol.precioVenta = parseFloat(
        articulo.precios.venta,
      ).toLocaleString("es-AR"));

    esVisible(config, "Stock") &&
      (objectCol.stock = (
        <StockDisponible articulo={articulo.repuesto_sucursal} />
      ));

    esVisible(config, "Proveedor") &&
      (objectCol.proveedor = articulo.proveedor.toUpperCase() || "---");

    objectCol.opciones = (
      <OpcionesCatalogo
        articulo={articulo}
        handleClickMenu={handleClickMenu}
        history={history}
        handleOpenVincularStock={handleOpenVincularStock}
      />
    );
    objectCol["idRepuestoProveedor"] = articulo.idRepuestoProveedor;
    columnsVisible.push(objectCol);
  });

  return lodash.compact(columnsVisible);
};

const redirectToML = (descripcion) => {
  return (
    <p
      onDoubleClick={() =>
        window.open(
          `https://listado.mercadolibre.com.ar/${descripcion.replace(" ", "-")}`,
        )
      }
    >
      {descripcion}
    </p>
  );
};
const seteoFilter = (code, setFilterOriginal) => {
  return <p onDoubleClick={() => setFilterOriginal(code)}>{code}</p>;
};

const openAjustarStock = (handleClickMenu, articulo, code) => {
  return <p onDoubleClick={() => handleClickMenu(articulo, "Stock")}>{code}</p>;
};
