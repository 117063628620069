export function getDataPlazos(plazos, montoTotal) {
  let newPlazos = plazos.sort(function (a, b) {
    return Number(b.porcentaje) - Number(a.porcentaje);
  });
  let newAmount = montoTotal / (1 + Number(newPlazos[0].porcentaje));

  return { newPlazos, newAmount };
}

export function getPriceNetoGravado(subtotal, descuento, recargo) {
  const newDescuento = descuento ? Number(descuento.toFixed(2)) : 0;
  const newRecargo = recargo ? Number(recargo.toFixed(2)) : 0;

  const neto = Number(subtotal.toFixed(2)) - newDescuento + newRecargo;

  return Number(neto.toFixed(2));
}

/**
 * @description Función que obtiene el cliente del comprobante.
 * Casos:
 * 1- Si tiene factura:
 *  a) Si es factura agrupada:
 *    a.1) Si es remito devuelve el cliente de la VENTA
 *    a.2) Si no es remito devuelve el cliente de la FACTURA
 *  b) Si es factura normal: (No importa si es remito o no ya que siempre debe devolver el cliente de la factura)
 *   b.1) Si tiene cliente tercero devuelve el cliente tercero de la factura
 *   b.2) Si no tiene cliente tercero y tiene cliente de sucursal devuelve el cliente real de la factura
 *   b.3) Si no tiene cliente tercero ni cliente de sucursal devuelve null
 * 2- Si no tiene factura devuelve null
 * @returns {Object} Retorna el cliente del comprobante
 */
export function getClienteComprobante(factura, venta, remito) {
  let cliente = null;
  if (factura) {
    if (factura.is_agrupada) {
      if (remito) {
        cliente = venta.cliente
          ? {
              razonSocial: venta.cliente.razonSocial
                ? venta.cliente.razonSocial
                : "Consumidor Final",
              domicilio: venta.cliente.domicilio
                ? venta.cliente.domicilio
                : null,
              cuit: venta.cliente.CUIT ? venta.cliente.CUIT : null,
              dni: venta.cliente.numeroDocumento
                ? venta.cliente.numeroDocumento
                : null,
              estadoTributario: venta.cliente.estadoTributario
                ? venta.cliente.estadoTributario.descripcion
                : "Consumidor Final",
            }
          : null;
      } else {
        cliente = factura.idClienteDeSucursal
          ? {
              razonSocial: factura.idClienteDeSucursal.idCliente.razonSocial,
              domicilio: factura.idClienteDeSucursal.idCliente.domicilio,
              cuit: factura.idClienteDeSucursal.idCliente.CUIT
                ? factura.idClienteDeSucursal.idCliente.CUIT
                : null,
              dni: factura.idClienteDeSucursal.idCliente.numeroDocumento
                ? factura.idClienteDeSucursal.idCliente.numeroDocumento
                : null,
              estadoTributario:
                factura.idClienteDeSucursal.idCliente.idEstadoTributario
                  .descripcion,
            }
          : null;
      }
    } else {
      if (factura.cliente_terceros) {
        cliente = {
          razonSocial: factura.cliente_terceros.razon_social,
          domicilio: factura.cliente_terceros.domicilio,
          cuit: factura.cliente_terceros.cuit
            ? factura.cliente_terceros.cuit
            : null,
          dni: factura.cliente_terceros.numero_documento
            ? factura.cliente_terceros.numero_documento
            : null,
          estadoTributario:
            factura.cliente_terceros.estado_tributario.descripcion,
        };
      } else {
        cliente = factura.idClienteDeSucursal
          ? {
              razonSocial: factura.idClienteDeSucursal.idCliente.razonSocial,
              domicilio: factura.idClienteDeSucursal.idCliente.domicilio,
              cuit: factura.idClienteDeSucursal.idCliente.CUIT
                ? factura.idClienteDeSucursal.idCliente.CUIT
                : null,
              dni: factura.idClienteDeSucursal.idCliente.numeroDocumento
                ? factura.idClienteDeSucursal.idCliente.numeroDocumento
                : null,
              estadoTributario:
                factura.idClienteDeSucursal.idCliente.idEstadoTributario
                  .descripcion,
            }
          : null;
      }
    }
  }
  return cliente;
}

export function textAbbreviation(text) {
  // Reemplaza 'percepciones' o 'percepción' e 'impuestos' o 'impuesto' por 'percep' e 'imp'
  const modifiedText = text
    .toLowerCase()
    .replace("percepciones", "percep")
    .replace("percepción", "percep")
    .replace("impuestos", "imp")
    .replace("impuesto", "imp");

  return modifiedText;
}
