import React from "react";
import { styles } from "../../assets/css/styleA4";
import { View, Text } from "@react-pdf/renderer";
import { Line_A4_Color, Line_A4_corte } from "../../Components/raya";
import BodyTable from "../../Components/TableGeneric/BodyTable";
import HeaderTable from "../../Components/TableGeneric/HeaderTable";
import { columnsPresupuesto } from "../../Components/TableReport/utils";
import HeaderA4CI from "./HeaderA4CI";
import ResumenCIA4 from "./resumenCIA4";
import ResumenPagos from "./resumenPagos";

export default function BodyCIA4({ data, tipo, config, remito }) {
  console.log(data);
  console.log({ config });
  const getFixedHeaderTable = () => {
    if (tipo === "ventaA4") {
      return data.venta.detalle_ventas.length > 22 ? true : false;
    } else {
      if (config.es_duplicado) {
        return false;
      } else {
        return data.venta.detalle_ventas.length > 28 ? true : false;
      }
    }
  };

  // const getDescripcion = (name) => {
  //   return name.substr(0, 65).trim() + (name.length > 65 ? "..." : "");
  // };

  return (
    <>
      <HeaderA4CI
        fixed={tipo === "ventaA4" ? true : false}
        logoEmpresa={data.logo}
        sucursal={data.sucursal}
        venta={data.venta}
        config={config}
        titleNro={remito ? "REMITO" : "COMPROBANTE"}
        nro={data.venta.idVenta}
      />

      <Line_A4_Color />
      <View style={{ minHeight: 95, maxHeight: 95 }}>
        <View style={styles.containerRow}>
          <View style={{ flexDirection: "column", width: 150 }}>
            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 150 })}>
                <Text style={styles.text2Title}>{`Sr.(es):`}</Text>
                <Text style={styles.text2}>
                  {data.venta.cliente
                    ? data.venta.cliente.razonSocial
                    : " Consumidor Final"}
                </Text>
              </View>
            </View>
            {data.venta.cliente ? (
              <View style={{ flexDirection: "row" }}>
                <View style={(styles.containerColumn, { width: 150 })}>
                  <Text style={styles.text2Title}>{`Domicilio:`}</Text>
                  <Text style={styles.text2}>
                    {data.venta.cliente && data.venta.cliente.domicilio
                      ? data.venta.cliente.domicilio
                      : "---"}
                  </Text>
                </View>
              </View>
            ) : null}

            {data.venta.cliente ? (
              <View style={{ flexDirection: "row" }}>
                <View style={(styles.containerColumn, { width: 150 })}>
                  <Text style={styles.text2Title}>{`Correo electrónico:`}</Text>
                  <Text style={styles.text2}>
                    {data.venta.cliente
                      ? data.venta.cliente.correoElectronico
                      : "---"}
                  </Text>
                </View>
              </View>
            ) : null}
          </View>

          <View style={{ flexDirection: "column", width: 150 }}>
            {data.venta.cliente ? (
              <View style={{ flexDirection: "row" }}>
                <View style={(styles.containerColumn, { width: 150 })}>
                  <Text style={styles.text2Title}>{`CUIT:`}</Text>
                  <Text style={styles.text2}>
                    {data.venta.cliente && data.venta.cliente.CUIT
                      ? data.venta.cliente.CUIT
                      : "---"}
                  </Text>
                </View>
              </View>
            ) : null}

            <View style={{ flexDirection: "row" }}>
              <View style={(styles.containerColumn, { width: 150 })}>
                <Text style={styles.text2Title}>{`Observación:`}</Text>
                <Text style={styles.text2}>
                  {data.venta.descripcion || "Ninguna"}
                </Text>
              </View>
            </View>
            {data.venta.responsable_venta ? (
              <View style={(styles.containerColumn, { width: 150 })}>
                <View style={(styles.containerColumn, { width: 150 })}>
                  <Text style={styles.text2Title}>{`Responsable:`}</Text>
                  <Text style={styles.text2}>
                    {data.venta.responsable_venta &&
                    data.venta.responsable_venta.nombre
                      ? data.venta.responsable_venta.nombre
                      : "---"}
                  </Text>
                </View>
              </View>
            ) : null}
          </View>

          {!remito && (
            <View style={{ flexDirection: "column", width: 150 }}>
              {data.venta.cliente ? (
                <View style={{ flexDirection: "row" }}>
                  <View style={(styles.containerColumn, { width: 150 })}>
                    <Text style={styles.text2Title}>{`Tel.:`}</Text>
                    <Text style={styles.text2}>
                      {data.venta.cliente
                        ? data.venta.cliente.telefonoContacto
                        : "---"}
                    </Text>
                  </View>
                </View>
              ) : null}

              <View style={{ flexDirection: "row" }}>
                <View style={(styles.containerColumn, { width: 150 })}>
                  <Text style={styles.text2Title}>{`Formas de Pago:`}</Text>
                  <ResumenPagos
                    data={data.pagos_cliente}
                    styles={styles.text2}
                  />
                </View>
              </View>
            </View>
          )}
        </View>
      </View>

      <HeaderTable
        fixed={getFixedHeaderTable()}
        columns={columnsPresupuesto(config)}
      />
      {data.venta.detalle_ventas.map((x) => (
        <BodyTable
          config={config}
          descripcion={x.newDescripcion}
          cant={x.cantidad !== "" ? parseFloat(x.cantidad) : ""}
          precio={
            x.subtotal !== ""
              ? Number(
                  (Number(x.subtotal) / x.cantidad).toFixed(2),
                ).toLocaleString("es-AR")
              : ""
          }
          subtotal={
            x.subtotal !== ""
              ? Number(Number(x.subtotal).toFixed(2)).toLocaleString("es-AR")
              : ""
          }
          noLine={x.noLine}
          remito={remito}
          listaPrecio={x.nombre_lista_precios ? x.nombre_lista_precios : "---"}
        />
      ))}

      {/** Resumen CI contiene el resumen de los montos y los datos que aparecen en el pie de pagina del pdf */}

      <ResumenCIA4 data={data} tipo={tipo} config={config} remito={remito} />

      {tipo !== "ventaA4" && (
        <View>
          <Line_A4_corte />
        </View>
      )}
    </>
  );
}
