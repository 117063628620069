import React, { useState } from "react";
import { Grid, Button } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import ModalReutilizable from "../../../components/ait-reusable/Modal/index";
import ContentDescargaTXT from "./ContentDescargaTXT";
import ButtonGeneral from "../../../components/ait-reusable/Button/ButtonGeneral";
import moment from "moment";
import axios from "axios";
import { downloadTxtRetenciones } from "../../../requests/urls";
import { errorNotification } from "../../../components/Notifications";

export default function HeaderTable({}) {
  const [openDescarga, setOpenDescarga] = useState(false);
  const [values, setValues] = useState({
    month: moment().month(),
    year: moment().year(),
  });
  const [loadingDescarga, setLoadingDescarga] = useState(false);
  let date = new Date();

  const descargarRetenciones = () => {
    const fechaInicio = moment({
      year: Number(values.year),
      month: Number(values.month),
    })
      .startOf("month")
      .toDate();
    const fechaFin =
      Number(values.month) === date.getMonth() &&
      Number(values.year) === date.getFullYear()
        ? date
        : moment({ year: Number(values.year), month: Number(values.month) })
            .endOf("month")
            .toDate();
    let fileName = `Retenciones${moment(fechaInicio).format(
      "YYYY-MM-DD",
    )}_${moment(fechaFin).format("YYYY-MM-DD")}.txt`;
    setLoadingDescarga(true);

    axios({
      url: downloadTxtRetenciones,
      params: {
        desde: moment(fechaInicio).format("YYYY-MM-DD"),
        hasta: moment(fechaFin).format("YYYY-MM-DD"),
      },
      headers: {
        Authorization: `Token ${localStorage.getItem("token")}`,
        "Content-Disposition": `attachment; filename=${fileName}`,
        "Content-Type":
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      responseType: "arraybuffer", // important
    })
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: "application/octet-stream" }),
        );
        const link = document.createElement("a");
        link.href = url;
        link.download = fileName;
        link.target = "_blank";
        link.click();
        setLoadingDescarga(false);
      })
      .catch((err) => {
        console.log(err);
        errorNotification(
          "No se ha podido descargar el txt, intente nuevamente.",
        );
        setLoadingDescarga(false);
      });
  };
  return (
    <>
      <Grid
        container
        item
        justifyContent="flex-end"
        xs={12}
        style={{ paddingBottom: 8 }}
      >
        <Button
          style={{ fontWeight: "bold", outline: "none" }}
          disableElevation
          variant="contained"
          size="small"
          onClick={() => setOpenDescarga(true)}
          startIcon={<GetAppIcon />}
        >
          Descargar retenciones
        </Button>
      </Grid>

      <ModalReutilizable
        open={openDescarga}
        handleClose={() => {
          setValues({
            month: moment().month(),
            year: moment().year(),
          });
          setOpenDescarga(false);
        }}
        title="Descarga de retenciones"
        size={"xs"}
        content={<ContentDescargaTXT values={values} setValues={setValues} />}
        actions={
          <ButtonGeneral
            click={() => descargarRetenciones()}
            message="DESCARGAR"
            disabled={Boolean(parseInt(values.year) > moment(date).year())}
          />
        }
        openBackdrop={loadingDescarga}
      />
    </>
  );
}
